
import { computed, defineComponent, onMounted, ref, watch, watchEffect } from "vue";
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonPage,
  IonRouterOutlet,
  loadingController,
} from "@ionic/vue";
import { home, scan, wallet, person, time } from "ionicons/icons";
import { useStore } from "vuex";
import { requestUserInfo } from "@/api/profile";
import router from "@/router";
import { showLoading } from "@/helpers/loadingHelper";
import { loadHistoryList } from "@/api/history";
import moment from "moment";
import { connect } from "@/common/websocket";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "TabsPage",
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    const store = useStore();
    const tabSelected = ref("dashboard");
    const customerId = computed(() => store.getters.auth.customerId) as any;
    const navigate = (url: string) => {
      router.replace(url);
    };
    const route = useRoute();
    const fromDate = ref(moment().subtract(30, "days").startOf("days").toDate());
    const toDate = ref(moment().endOf("days").toDate());

    const showTabBar = computed(() => {
      const url = route.path.split("/");
      if (url.includes("verification")) {
        return false;
      }
      return true;
    });

    const loadHistory = () => {
      if (!customerId.value) return;
      showLoading();
      const params = {
        status: 1,
        dateFrom: fromDate.value.getTime(),
        dateTo: toDate.value.getTime(),
        type: 0,
        page: 1,
        size: 10,
      };

      loadHistoryList(customerId.value, params)
        .then((res) => {
          store.dispatch("history/loadHistory", res.data);
        })
        .finally(() => {
          loadingController.dismiss();
        });
    };

    const fetchCustomerInfo = () => {
      requestUserInfo(customerId.value).then((res) => {
        store.dispatch("auth/getInfo", res.data);
      });
    };

    onMounted(() => {
      connect();
    });

    const beforeTabChange = (event) => {
      // do something before tab change
      tabSelected.value = event.tab;
      if (event.tab === "history") {
        loadHistory();
      }

      fetchCustomerInfo();
    };

    const afterTabChange = () => {
      // do something after tab change
    };

    return {
      beforeTabChange,
      afterTabChange,
      navigate,
      tabSelected,
      showTabBar,
      // icon
      home,
      scan,
      wallet,
      person,
      time,
    };
  },
});
